import { inject, Injectable } from "@angular/core";
import { UsersService } from "@kno2/data-access/users";
import { UserProfile } from "@kno2/data-access/users/user-profile.model";
import { BehaviorSubject } from "rxjs";
import { LocalStorageService } from "../../services/local-storage/local-storage.service";

@Injectable({
    providedIn: "root"
})
export class SessionService {
    private readonly localStorageKey = "ProfileData";
    private readonly usersService = inject(UsersService);
    private readonly localStorageService = inject(LocalStorageService);

    public profileData$ = new BehaviorSubject<UserProfile | null>(this.localStorageService.get(this.localStorageKey));

    /**
     * Gets the user profile from memory. If the profile is not in memory, it will be fetched from local storage, set in memory, and returned.
     *
     * @returns The user profile.
     */
    public getProfile(): UserProfile {
        let profile = this.profileData$.value;

        if (!profile) {
            profile = this.localStorageService.get(this.localStorageKey);
            this.profileData$.next(profile);
        }
        return profile;
    }

    /**
     * Refetches the user profile from the server and updates local storage.
     *
     * Use this when you need the profile from the server and to update memory and local storage.
     *
     * @returns The user profile.
     */
    public async refreshProfile(): Promise<UserProfile> {
        const profile = await this.usersService.getUserProfile();
        this.setProfile(profile);

        return profile;
    }

    /**
     * Gets the user profile from the server.
     *
     * @returns The user profile.
     *
     */
    public async getProfileAsync(): Promise<UserProfile> {
        let profile = this.getProfile();
        if (!profile || !Object.entries(profile).length) {
            profile = await this.refreshProfile();
        }

        return profile;
    }

    /**
     * Gets the client's public IP address.
     *
     * @returns The client IP address.
     *
     * @deprecated Use `UsersService.getClientIp()` instead.
     */
    public getClientIp(): Promise<string> {
        return this.usersService.getClientIp();
    }

    /**
     * Sets the user profile in memory and local storage.
     *
     * @param profile The new user profile.
     */
    public setProfile(profile: UserProfile): void {
        this.profileData$.next(profile);
        this.localStorageService.set(this.localStorageKey, profile);
    }

    /**
     * Removes the user profile from local storage.
     */
    public removeProfile(): void {
        this.localStorageService.remove(this.localStorageKey);
    }

    /**
     * Checks if the user is in one of the given roles.
     *
     * @param roles The roles to test.
     * @returns `true` if the user is in one of the roles. `false` otherwise.
     */
    public userInRole(roles: string | string[]): boolean {
        const profile = this.getProfile();
        const roleArray = Array.isArray(roles) ? roles : roles.split(",");
        return roleArray.some((role) => profile?.roles.includes(role));
    }

    /**
     * Checks if the user is in the SysAdmin or SysAdminReporting role.
     *
     * @returns `true` if the user is in the SysAdmin or SysAdminReporting role. `false` otherwise.
     */
    public isSysAdmin(): boolean {
        return this.userInRole(["SysAdmin", "SysAdminReporting"]);
    }

    /**
     * Checks if a UI resource action is present on the user profile.
     *
     * @param resource The resource key.
     * @param action The action to check for.
     * @returns `true` if the UI resource action is present on the user profile. `false` otherwise.
     */
    public hasUIResourceAction(resource: string, action: string): boolean {
        const actions = this.getUIResourceActions(resource) ?? [];
        return actions.includes(action);
    }

    /**
     * Gets the UI resource actions for the given resource key.
     *
     * @param resource The resource key.
     * @returns The UI resource actions for the given resource key.
     */
    public getUIResourceActions(resource: string): UserProfile["uiResources"][string] {
        const profile = this.getProfile();
        return profile.uiResources[resource];
    }

    /**
     * Gets the default route for the user's active role.
     *
     * @returns The default route for the user's active role.
     */
    public getDefaultRouteForActiveRole(): string {
        let path = "/intake";
        if (this.isSysAdmin()) {
            path = "/admin/dashboard/messaging";
        }
        if (this.userInRole("Administrator")) {
            path = "/account/dashboard";
        }
        return path;
    }
}
