import { Component, inject, Input } from "@angular/core";
import { UpgradeModule } from "@angular/upgrade/static";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { noop } from "angular";

@Component({
    selector: "kno2-confirmation-dialog",
    standalone: true,
    imports: [],
    templateUrl: "./confirmation-dialog.component.html"
})
export class ConfirmationDialogComponent {
    private readonly activeModal = inject(NgbActiveModal);
    private readonly upgrade = inject(UpgradeModule);
    private readonly notificationService = this.upgrade.$injector.get("NotificationService");

    @Input() public headerText = "Confirm";
    @Input() public bodyText = "Are you sure?";
    @Input() public okText = "OK";
    @Input() public cancelText = "Cancel";
    @Input() public successNotificationText: string;
    @Input() public errorNotificationText: boolean;
    @Input() public confirmFn = noop;

    protected isConfirmInProgress = false;

    public async confirm(): Promise<void> {
        try {
            this.isConfirmInProgress = true;

            await this.confirmFn();

            if (this.successNotificationText) {
                this.notificationService.success(this.successNotificationText);
            }

            this.activeModal.close(true);
        } catch (err) {
            if (this.errorNotificationText) {
                this.notificationService.errorToaster(this.errorNotificationText);
            }
        } finally {
            this.isConfirmInProgress = false;
        }
    }

    public cancel(): void {
        this.activeModal.dismiss();
    }
}
