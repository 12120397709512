import { Component, OnDestroy, effect, inject } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { RouterOutlet } from "@angular/router";
import { UpgradeModule } from "@angular/upgrade/static";
import { AuthService } from "@auth0/auth0-angular";
import { WINDOW_TOKEN } from "@kno2/core/tokens";
import { SocketService, TooltipsService } from "@kno2/shared/services";
import { SessionService, SessionTimeoutDirective } from "@kno2/shared/ui/session";

@Component({
    imports: [RouterOutlet, SessionTimeoutDirective],
    selector: "app-root",
    templateUrl: "./app.component.html",
    standalone: true
})
export class AppComponent implements OnDestroy {
    private readonly window = inject(WINDOW_TOKEN);
    private readonly socketService = inject(SocketService);
    private readonly tooltipsService = inject(TooltipsService);
    private readonly authService = inject(AuthService);
    private readonly sessionService = inject(SessionService);
    private readonly upgrade = inject(UpgradeModule);
    private readonly featureService = this.upgrade.$injector.get("FeatureService");

    private isAuthenticated = toSignal(this.authService.isAuthenticated$);

    public constructor() {
        const profile = toSignal(this.sessionService.profileData$);
        effect(() => {
            if (this.isAuthenticated() && !!profile()) {
                this.featureService.load();
                this.socketService.init();
            }
        });
    }

    public async ngOnInit(): Promise<void> {
        try {
            this.tooltipsService.init();

            this.window.onbeforeunload = () => this.ngOnDestroy();
        } catch (err) {
            console.error(err);
        }
    }

    public ngOnDestroy(): void {
        this.window.onbeforeunload = null;
        this.socketService?.disconnect();
    }
}
