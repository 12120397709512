import { Directive, HostListener, inject } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { noop } from "angular";
import { Duration } from "luxon";
import { filter, firstValueFrom } from "rxjs";
import { SessionTimeoutComponent } from "./session-timeout.component";
import { SessionService } from "./session.service";

@Directive({
    selector: "[kno2SessionTimeout]",
    standalone: true
})
export class SessionTimeoutDirective {
    private readonly modalService = inject(NgbModal);
    private readonly sessionService = inject(SessionService);

    private timeout = 10;
    private prompt: ReturnType<typeof setTimeout>;
    private isOpen = false;

    public async ngOnInit(): Promise<void> {
        const profile = await firstValueFrom(this.sessionService.profileData$.pipe(filter((profile) => !!profile && !!Object.entries(profile).length)));
        this.timeout = profile.sessionTimeoutMinutes;
        this.schedulePrompt();
    }

    @HostListener("document:click", ["$event"])
    @HostListener("document:keydown", ["$event"])
    private schedulePrompt() {
        if (this.isOpen) return;
        if (this.prompt) clearTimeout(this.prompt);

        const delay = Duration.fromObject({ minutes: this.timeout - 1, seconds: 5 }).as("milliseconds");
        this.prompt = setTimeout(async () => {
            const modal = this.modalService.open(SessionTimeoutComponent, {
                windowClass: "modal-800"
            });
            this.isOpen = true;

            await modal.result.catch(noop);
            this.isOpen = false;
        }, delay);
    }
}
